/************
 * Developed by : Shiva Software Solutions
 * Date : 28-06-2021
 * Descriptions : Route Navigation
 * Dependicies : To use @material-ui/core
 ************/
import React, {useContext} from 'react';
import {renderRoutes} from 'react-router-config';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Switch, useLocation} from 'react-router-dom';
import {AppSuspense} from '../../index';
import routes from '../../../modules';
import Scrollbar from '../Scrollbar';
import AppContext from '../../utility/AppContext';
import PropTypes from 'prop-types';
import AppFooter from '../AppLayout/AppFooter';
import Box from '@material-ui/core/Box';
import {RouteTransition} from '../../../shared/constants/AppEnums';
import AppErrorBoundary from '../AppErrorBoundary';
import {useIdleTimer} from 'react-idle-timer';
import {useAuthUser} from '../../utility/AppHooks';
import {
  onCognitoUserSignOut,
  onJWTAuthSignout,
  onSignOutAuth0User,
  onSignOutFirebaseUser,
} from '../../../redux/actions';
import {AuthType} from '../../../shared/constants/AppEnums';
import {useSelector, useDispatch} from 'react-redux';
import jwtAxios from '../../../@crema/services/auth/jwt-auth/jwt-api';

const TransitionWrapper = ({children}) => {
  const {rtAnim} = useContext(AppContext);
  const location = useLocation();
  if (rtAnim === RouteTransition.NONE) {
    return <>{children}</>;
  }
  const currentKey = location.pathname.split('/')[1] || '/';
  return (
    <TransitionGroup appear enter exit>
      <CSSTransition
        key={currentKey}
        timeout={{enter: 300, exit: 300}}
        classNames={rtAnim}>
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

TransitionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
const ContentView = () => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  //Assign Login user
  const LoginUser = useSelector(({auth}) => auth);

  const handleOnIdle = (event) => {
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    if (getLastActiveTime()) {
      if (user && user.authType === AuthType.JWT_AUTH) {
        let pushdata = {
          userId: user.userId,
          userCode: user.userCode,
          onlineStatus: 'Logout',
          sessionID: user.sessionID,
        };
        dispatch(onJWTAuthSignout(pushdata));
      }
    }
  };

  // const handleOnActive = event => {
  //   console.log('user is active', event)
  //   console.log('time remaining', getRemainingTime())
  // }

  const handleOnAction = (event) => {
    if (event) {
      setTimeout(() => {
        if (LoginUser?.user?.userCode && localStorage.getItem('roleId')) {
          var currentmilli = new Date().getTime();
          if (localStorage.getItem('lastInsertTime')) {
            var localtime = parseFloat(localStorage.getItem('lastInsertTime'));
            var dif = Math.abs(currentmilli - localtime) / 1000;
            if (dif > 300) {
              localStorage.setItem('lastInsertTime', currentmilli);
              let pushdata = {
                userCode: parseInt(LoginUser?.user?.userCode) || 0,
                session_id: LoginUser?.user?.sessionID || '',
              };
              CheckActiveSession(pushdata);
            }
          } else {
            localStorage.setItem('lastInsertTime', currentmilli);
            let pushdata = {
              userCode: parseInt(LoginUser?.user?.userCode) || 0,
              session_id: LoginUser?.user?.sessionID || '',
            };
            CheckActiveSession(pushdata);
          }
        }
      }, 2000);
    }
  };
  const logoutUser = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (user && user.authType === AuthType.AWS_COGNITO) {
      dispatch(onCognitoUserSignOut());
    } else if (user && user.authType === AuthType.FIREBASE) {
      dispatch(onSignOutFirebaseUser());
    } else if (user && user.authType === AuthType.AUTH0) {
      dispatch(onSignOutAuth0User());
    } else if (user && user.authType === AuthType.JWT_AUTH) {
      // signout redux api calling
      let pushdata = {
        userId: user.userId,
        userCode: user.userCode,
        onlineStatus: 'Logout',
        sessionID: user.sessionID,
        activeSession: true,
      };
      dispatch(onJWTAuthSignout(pushdata));
    }
  };
  const CheckActiveSession = async (body) => {
    try {
      // var body = {userCode: user.userCode};
      jwtAxios
        .post('v1/userMgmt/checkSessionTimingJwt', body)
        .then((res) => {
          body['jwtToken'] = res.data.body.token;
          jwtAxios
            .post('v1/userMgmt/checkSessionTiming', body)
            .then((res) => {
              if (
                res &&
                res.data &&
                res.data.message &&
                res.data.message.includes('Error')
              ) {
                logoutUser();
              }
            })
            .catch((error) => {
              console.log('insert session error', error);
            });
        })
        .catch((error) => {
          console.log('insert session error', error);
        });
    } catch (err) {
      console.log('insert session error', err);
    }
  };
  const {getRemainingTime, getLastActiveTime} = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <Scrollbar>
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        className='main-content-view'>
        <AppSuspense>
          <AppErrorBoundary>
            {/* <IdleTimer
              ref={idleTimer}
              element={document}
              onActive={onActive}
              onIdle={onIdle}
              onAction={onAction}
              debounce={250}
              timeout={idletime.timeout}
            /> */}
            <TransitionWrapper>{renderRoutes(routes)}</TransitionWrapper>
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </Scrollbar>
  );
};

export default ContentView;
