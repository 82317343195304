import React, {useContext, useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {useAuthUser} from '../../utility/AppHooks';
import AppContext from '../../utility/AppContext';
import {makeStyles} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import {orange} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import ChangePasswordPopup from './ChangepasswordPopup';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Divider from '@material-ui/core/Divider';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
// import confirmation dialog
import ConfirmationDialog from './confirmationDialog';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {useHistory} from 'react-router-dom';
import Notification from '../../../modules/common/Notification/Notification';
import {
  storeOnlineOfflineStatus,
  onGetAdminPendingPresCount,
  getHighPriorityAlert,
} from '../../../redux/actions';
import {HIGHPRIORITYCOUNT} from '../../../shared/constants/ActionTypes';
// for style
const useStyles = makeStyles((theme) => {
  return {
    userRoot: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      position: 'relative',
    },
    avatar: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    autowidht: {
      width: 'auto',
      height: 'auto',
      marginLeft: 6,
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    pointer: {
      cursor: 'pointer',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 15,
      fontWeight: Fonts.MEDIUM,
      color: (props) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },
    notifiSize: {
      fontSize: '12px',
      color: 'black',
    },
    bgColor: {
      color: (props) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },
    OnlineMenu: {
      display: 'none',
    },
    Online: {
      color: '#21d221',
      width: 18,
      height: 18,
    },
    OfflineMenu: {
      display: 'block',
    },
    Offline: {
      color: '#ff0000',
      width: 18,
      height: 18,
    },
    mar3: {
      marginRight: '12px',
    },
    onlineStatus: {
      position: 'absolute',
      zIndex: 99,

      color: '#21d221',
      width: 18,
      height: 18,
      top: '-7%',
      left: '17%',
    },
    offlineStatus: {
      position: 'absolute',
      zIndex: 99,

      color: '#ff0000',
      width: 18,
      height: 18,
      top: '-7%',
      left: '17%',
    },
    outerBox: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
    },
  };
});

const HorUserInfo = ({bgType = 'colored'}) => {
  // dispatch for using redux api and variables
  const dispatch = useDispatch();
  const history = useHistory();
  // get the auther data from redux
  const user = useAuthUser();
  // for ui designs style
  const {themeMode} = useContext(AppContext);
  const classes = useStyles({themeMode, bgType});
  // state variable declaration
  const [changePassword, setChangePasswordPopup] = useState(false);
  const LoginUser = useSelector(({auth}) => auth);
  const [alertNotification, setAlertNotification] = useState({});

  const pendingApprovalCount = useSelector(
    ({master}) => master.admin_pending_Approval_count,
  );
  const highPriorityAlert = useSelector(
    ({master}) => master.high_priority_count,
  );
  const notificationList = useSelector(
    ({master}) => master.admin_pending_approval_list || [],
  );
  const [interVel, setInterVel] = useState(null);

  const [openpopup, setopenpopup] = useState(false);
  const [getStatus, setgetStatus] = React.useState('Online');
  const [statusChange, setStatusChange] = React.useState({
    onlineshow: {display: 'block'},
    onlinehide: {display: 'none'},
    offlineshow: {display: 'block'},
    offlinehide: {display: 'none'},
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationOpen, setNotificationOpen] = React.useState(false);

  // for user click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // for user click
  const handleNotificationClick = (event) => {
    setNotificationOpen(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(null);
  };
  // online/offline change event
  const statusChanges = (val) => {
    let pushdata = {
      userId: user.userId,
      userCode: user.userCode,
      onlineStatus: val,
    };
    dispatch(storeOnlineOfflineStatus(pushdata));
    setgetStatus(val);
    setAnchorEl(null);
  };
  // changepassword open popup event
  const openChangepasswordPopup = () => {
    setAnchorEl(null);
    setChangePasswordPopup(true);
  };

  // logout open popup event
  const logoutClick = () => {
    setAnchorEl(null);
    setopenpopup(true);
  };

  const navigateToApproved = () => {
    handleNotificationClose();
    history.push('/adminApproval');
  };

  useEffect(() => {
    if (Number(LoginUser?.user?.roleId) == 1) {
      dispatch(
        onGetAdminPendingPresCount({
          userCode: LoginUser?.user?.userCode,
          userId: LoginUser?.user?.userId,
        }),
      );
    }

    if (
      !(
        Number(LoginUser?.user?.roleId) == 4 &&
        Number(LoginUser?.user?.userCategory) == 1
      )
    ) {
      callHighPriority();
    }
  }, []);

  const callHighPriority = () => {
    dispatch(
      getHighPriorityAlert({
        userCode: LoginUser?.user?.userCode,
        userId: LoginUser?.user?.userId,
        sessionID: LoginUser?.user.sessionID,
      }),
    );
  };
  useEffect(() => {
    if (highPriorityAlert && highPriorityAlert?.highPriorityDuration) {
      let duration = highPriorityAlert?.highPriorityDuration;
      let alertArray = highPriorityAlert?.result;

      if (highPriorityAlert?.result && highPriorityAlert?.result.length > 0) {
        let notificationFrame = [];
        for (let i = 0; i < highPriorityAlert?.result.length; i++) {
          if (
            highPriorityAlert?.result[i]?.total &&
            Number(highPriorityAlert?.result[i]?.total) > 0
          ) {
            notificationFrame.push(
              'You have ' +
                highPriorityAlert?.result[i]?.total +
                ' prescriptions from ' +
                highPriorityAlert?.result[i]?.clientName +
                '.',
            );
          }
        }
        setAlertNotification(notificationFrame);
      }

      const timer = setInterval(() => {
        callHighPriority();
        dispatch({
          type: HIGHPRIORITYCOUNT,
          payload: {},
        });
        if (interVel) clearInterval(interVel);
      }, duration);

      setInterVel(timer);
      return () => {
        clearInterval(timer);
        dispatch({
          type: HIGHPRIORITYCOUNT,
          payload: {},
        });
      };
    } else {
      setAlertNotification({});
    }
  }, [highPriorityAlert]);
  return (
    <Box py={2} pl={{xs: 2, sm: 3, md: 5}}>
      <Box className={classes.outerBox}>
        {pendingApprovalCount &&
          pendingApprovalCount != 0 &&
          pendingApprovalCount != '0' &&
          Number(LoginUser?.user?.roleId) == 1 && (
            <Box
              className={classes.userRoot}
              display='flex'
              onClick={handleNotificationClick}>
              <Badge
                className={`${classes.badgeStyle} ${classes.mar3}`}
                badgeContent={pendingApprovalCount}
                color='secondary'>
                <NotificationsIcon className={classes.bgColor} />
              </Badge>
            </Box>
          )}
        <Box className={classes.userRoot} display='flex' onClick={handleClick}>
          <Avatar
            src='/assets/images/profile-user.png'
            className={classes.autowidht}
          />
          {user.roleId !== 1 ? (
            getStatus === 'Online' ? (
              <FiberManualRecordIcon className={classes.onlineStatus} />
            ) : (
              <FiberManualRecordIcon className={classes.offlineStatus} />
            )
          ) : (
            ''
          )}

          <Hidden>
            <Box ml={3} className={classes.userName}>
              {/* display name check */}
              {user.displayName ? user.displayName : user.email}
              <Box fontSize={13} fontWeight={Fonts.LIGHT}>
                {user.role}
              </Box>
            </Box>
          </Hidden>
        </Box>
      </Box>
      <Box className={classes.userInfo}>
        {/* online/offline menu changes in ui*/}
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {user.roleId !== 1 ? (
            <MenuItem
              onClick={() => statusChanges('Online')}
              style={
                getStatus === 'Online'
                  ? statusChange.onlinehide
                  : statusChange.onlineshow
              }>
              {' '}
              <FiberManualRecordIcon className={classes.Online} />
              Online
            </MenuItem>
          ) : null}

          {user.roleId !== 1 ? (
            <MenuItem
              onClick={() => statusChanges('Offline')}
              style={
                getStatus === 'Offline'
                  ? statusChange.offlinehide
                  : statusChange.offlineshow
              }>
              {' '}
              <FiberManualRecordIcon className={classes.Offline} />
              Offline
            </MenuItem>
          ) : null}
          {user.roleId !== 1 ? <Divider /> : null}
          <MenuItem onClick={() => openChangepasswordPopup()}>
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              logoutClick();
            }}>
            Logout
          </MenuItem>
        </Menu>
        {/* change password popup calling */}
        <ChangePasswordPopup
          open={changePassword}
          onDeny={setChangePasswordPopup}
          title='Change Password'
        />
        {/* confirmation dialog */}
        <ConfirmationDialog opendialog={openpopup} onDeny={setopenpopup} />
      </Box>
      {alertNotification && alertNotification.length > 0 && (
        <Notification message={alertNotification} key='notification' />
      )}
      <Menu
        id='simple-menu'
        anchorEl={notificationOpen}
        keepMounted
        open={Boolean(notificationOpen)}
        onClose={handleNotificationClose}>
        {notificationList &&
          notificationList?.length > 0 &&
          notificationList.map((item) => (
            <MenuItem onClick={() => navigateToApproved('')}>
              {' '}
              <span style={{fontSize: '14px', color: 'black'}}>
                <span style={{fontWeight: 600}}>{item?.erxId} </span>-{' '}
                <>
                  {' '}
                  Need approval for{' '}
                  {item?.newProcedure && item?.newProcedure.length > 0
                    ? 'Procedure'
                    : ''}
                  {item?.newProcedure &&
                  item?.newProcedure.length > 0 &&
                  item?.newInvestigation &&
                  item?.newInvestigation.length > 0
                    ? ', '
                    : ''}
                  {item?.newInvestigation && item?.newInvestigation.length > 0
                    ? 'Investigation'
                    : ''}
                  {((item?.newInvestigation &&
                    item?.newInvestigation.length > 0) ||
                    (item?.newProcedure && item?.newProcedure.length > 0)) &&
                  item?.newAllergy &&
                  item?.newAllergy.length > 0
                    ? ', '
                    : ''}
                  {item?.newAllergy && item?.newAllergy.length > 0
                    ? 'Allergy'
                    : ''}
                  {((item?.newInvestigation &&
                    item?.newInvestigation.length > 0) ||
                    (item?.newProcedure && item?.newProcedure.length > 0) ||
                    (item?.newAllergy && item?.newAllergy.length > 0)) &&
                  item?.newDisease &&
                  item?.newDisease.length > 0
                    ? ', '
                    : ''}
                  {/* {item?.newDisease &&
                  item?.newDisease.length > 0 &&
                  item?.newAllergy &&
                  item?.newAllergy.length > 0
                    ? ', '
                    : ''} */}
                  {item?.newDisease && item?.newDisease.length > 0
                    ? 'Disease'
                    : ''}
                </>
              </span>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

export default HorUserInfo;
