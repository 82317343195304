import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {Auth as awsAuth, Hub} from 'aws-amplify';
import {
  UPDATE_AUTH_USER,
  DIGI_L1TAG,
  PERMISSIONS,
  CHECK_SYSTEM_IP,
  RELOAD_LOGIN,
  USER_PERMISSIONS,
  FETCH_ERROR,
  GET_CAMERA_PERMISSION
} from '../../shared/constants/ActionTypes';
import {auth as firebaseAuth} from '../services/auth/firebase/firebase';
import {
  fetchStart,
  fetchSuccess,
  onGetLoggedInCognitoUser,
  setJWTToken,
} from '../../redux/actions';
import {AuthType} from '../../shared/constants/AppEnums';
import {defaultUser, userRole} from '../../shared/constants/AppConst';
import jwtAxios from '../services/auth/jwt-auth/jwt-api';
// for encrypt and decrypt
const crypto = require('crypto');
var algorithm = 'aes-256-ctr';
var password = 'd6F3Efeq';

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector(({auth}) => auth);
  const publicIp = require('public-ip');

  // const [refreshInterval, setRefreshInterval] = React.useState(60000 || 0);
  // useEffect(() => {
  //   if (refreshInterval && refreshInterval > 0 && user) {
  //     // const interval = setInterval(sesstionTiming, refreshInterval);
  //     // return () => clearInterval(interval);
  //   }
  // }, [user, refreshInterval]);

  useEffect(() => {
    const awsAuthUser = () =>
      new Promise((resolve) => {
        awsAuth
          .currentAuthenticatedUser()
          .then((user) => {
            resolve();
            if (user) {
              dispatch({
                type: UPDATE_AUTH_USER,
                payload: {
                  authType: AuthType.AWS_COGNITO,
                  uid: user.username,
                  displayName: user.attributes.name,
                  email: user.attributes.email,
                  role: defaultUser.role,
                  photoURL: user.photoURL,
                  token: user.signInUserSession.accessToken.jwtToken,
                },
              });
            }
          })
          .catch(function (error) {
            resolve();
          });
        return Promise.resolve();
      });

    const firebaseCheck = () =>
      new Promise((resolve) => {
        firebaseAuth.onAuthStateChanged((authUser) => {
          if (authUser) {
            dispatch({
              type: UPDATE_AUTH_USER,
              payload: {
                authType: AuthType.FIREBASE,
                uid: authUser.uid,
                displayName: authUser.displayName,
                email: authUser.email,
                role: defaultUser.role,
                photoURL: authUser.photoURL,
                token: authUser.refreshToken,
              },
            });
          }
          resolve();
        });
        return Promise.resolve();
      });

    const decryptvalue = (mobileno) => {
      try {
        var decipher = crypto.createDecipher(algorithm, password);
        var dec = decipher.update(mobileno, 'hex', 'utf8');
        dec += decipher.final('utf8');
        return dec;
      } catch (error) {
        throw new Error(error);
      }
    };

    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      // if (!token) {
      //   dispatch(fetchSuccess());
      //   return;
      // }
      dispatch(setJWTToken(token));
      try {
        const ipadd = await publicIp.v4();
        await jwtAxios
          .post('v1/userMgmt/checkSystemIpJwt', {ipaddress: ipadd})
          .then((res) => {
            dispatch(fetchSuccess());

            jwtAxios
              .post('v1/userMgmt/checkSystemIp', {
                ipaddress: ipadd,
                jwtToken: res.data.body.token,
              })
              .then((res) => {
                dispatch(fetchSuccess());

                if (res.data.body === 'success') {
                  var agentId = localStorage.getItem('agentId');
                  var paswd = localStorage.getItem('agentpwd');
                  dispatch({
                    type: CHECK_SYSTEM_IP,
                    payload: false,
                  });
                  const agentexist = localStorage.getItem('agentId');

                  if (agentexist) {
                    dispatch({
                      type: RELOAD_LOGIN,
                      payload: false,
                    });
                    localStorage.setItem('browserreload', false);
                    // password decryption
                    var decipher = crypto.createDecipher(algorithm, password);
                    var dec = decipher.update(paswd, 'hex', 'utf8');
                    dec += decipher.final('utf8');
                    const session_id = localStorage.getItem('session_id');
                    var logindata = {
                      agentId: agentId,
                      pwd: dec,
                      type: 'AppHook',
                      sessionID: session_id,
                      activateSession: 'No',
                    };
                    // login jwt
                    jwtAxios
                      .post('v1/userMgmt/loginjwt', logindata)
                      .then((res) => {
                        if (res.data.body !== '') {
                          if (
                            res.data.hasOwnProperty.call(res.data, 'message')
                          ) {
                            // if (res.data.message.includes('Error')) {
                            //   if (
                            //     res.data.message.includes('1') ||
                            //     res.data.message.includes('6')
                            //   ) {
                            //     dispatch({
                            //       type: FETCH_ERROR,
                            //       payload: 'Agent ID or password is incorrect',
                            //     });
                            //   }
                            //   if (res.data.message.includes('5')) {
                            //     dispatch({
                            //       type: FETCH_ERROR,
                            //       payload: 'User is locked',
                            //     });
                            //     dispatch({
                            //       type: LOGIN_ATTEMPT_ERROR,
                            //       payload: res.data.message.split(' ~ ')[1],
                            //     });
                            //   }
                            //   if (res.data.message.includes('6')) {
                            //     dispatch({
                            //       type: LOGIN_ATTEMPT_ERROR,
                            //       payload: res.data.message.split(' ~ ')[1],
                            //     });
                            //   } else {
                            //     dispatch({
                            //       type: LOGIN_ATTEMPT_ERROR,
                            //       payload: '',
                            //     });
                            //   }
                            // }
                          } else {
                            // localStorage.setItem('token', res.data.body.token);
                            localStorage.setItem('agentId', agentId);
                            localStorage.setItem('agentpwd', paswd);
                            logindata['jwtToken'] = res.data.body.token;
                            // login
                            // var body = {
                            //   email: '',
                            //   pwd: '',
                            //   jwtToken: localStorage.getItem('token'),
                            // };
                            // const session_id = localStorage.getItem('session_id');
                            jwtAxios
                              .post('v1/userMgmt/login', logindata)
                              .then((res) => {
                                if (res.data.body && res.data.body.data) {
                                  let sessionid = decryptvalue(
                                    res.data.body.data.sessionID,
                                  );
                                  if (res.data.body.data.sessionID) {
                                    if (sessionid !== session_id) {
                                      return;
                                    }
                                  } else {
                                    return;
                                  }
                                }
                                dispatch(fetchSuccess());
                                var response = res.data.body.data.userlogin[0];
                                let digiL2Tag = res?.data?.body?.data
                                  ?.digitizationL2Tag
                                  ? res?.data?.body?.data?.digitizationL2Tag
                                  : false;
                                var sessionId = res.data.body.data.sessionID;

                                if (
                                  response.agentId.toLowerCase() !==
                                  logindata.agentId.toLowerCase()
                                ) {
                                  return;
                                }

                                if (
                                  decryptvalue(response.pwd) !== logindata.pwd
                                ) {
                                  return;
                                }
                                var snoozeres = res.data.body.data.snooze
                                  ? res.data.body.data.snooze[0]
                                  : '';
                                var permissions =
                                  res.data.body.data.permissionList;
                                var roleName = userRole.user.find(
                                  (e) => e.roleId === response.roleId,
                                );
                                var camPermission = res.data.body.data['cameraDashPermission']
                                dispatch({
                                  type: GET_CAMERA_PERMISSION,
                                  payload: camPermission
                                });
                                // dispatch({
                                //   type:RELOAD_LOGIN,
                                //   payload:true
                                // })
                                dispatch({
                                  type: USER_PERMISSIONS,
                                  payload:
                                    response.userPermissions &&
                                    response.userPermissions.permission
                                      ? response.userPermissions.permission
                                      : {},
                                });
                                localStorage.setItem('roleId', response.roleId);
                                dispatch({
                                  type: DIGI_L1TAG,
                                  payload: digiL2Tag,
                                });
                                dispatch({
                                  type: UPDATE_AUTH_USER,
                                  payload: {
                                    authType: AuthType.JWT_AUTH,
                                    displayName:
                                      response.fName + ' ' + response.lName,
                                    email: response.email,
                                    userId:
                                      response.userId === null ||
                                      response.userId === undefined
                                        ? ''
                                        : response.userId,
                                    roleId: response.roleId,
                                    statusCode: response.status,
                                    userCode: response.userCode,
                                    agentId: response.agentId,
                                    // role: response.roleId.tosString(),
                                    // token: token,
                                    // photoURL: '',
                                    // displayName: 'Aruna',
                                    // email: 'arunadevi@gmail.com',
                                    role: roleName.roleName,
                                    token: token,
                                    paswd: response.paswd,
                                    photoURL: '',
                                    userPermissions: response.userPermissions,
                                    sessionID: sessionId,
                                    userCategory: response.usercategory,
                                    digitizationL2Tag: digiL2Tag,
                                  },
                                });

                                if (snoozeres) {
                                  localStorage.setItem(
                                    'snoozeTime',
                                    JSON.stringify(snoozeres),
                                  );
                                }

                                dispatch({
                                  type: PERMISSIONS,
                                  payload: permissions,
                                });
                              })
                              .catch((error) => {
                                dispatch({
                                  type: RELOAD_LOGIN,
                                  payload: true,
                                });
                                localStorage.clear();
                                sessionStorage.clear();
                                // dispatch({type: FETCH_ERROR, payload: error.message});
                              });
                          }
                        } else {
                          //redirect to login
                          dispatch({
                            type: RELOAD_LOGIN,
                            payload: true,
                          });
                          localStorage.clear();
                          sessionStorage.clear();
                        }
                      })
                      .catch((error) => {
                        // dispatch({
                        //   type: FETCH_ERROR,
                        //   payload: error.response.data.message.split(':')[1].trim(),
                        // });
                        // dispatch(
                        //   onWriteCloudWatchLog(
                        //     streamname,
                        //     filename,
                        //     'onJwtSignIn',
                        //     '0',
                        //     error,
                        //   ),
                        // );
                      });
                  } else {
                    dispatch({
                      type: RELOAD_LOGIN,
                      payload: true,
                    });

                    localStorage.setItem('browserreload', true);
                    localStorage.clear();
                    sessionStorage.clear();
                  }
                } else {
                  dispatch({
                    type: CHECK_SYSTEM_IP,
                    payload: true,
                  });
                }
              })
              .catch((error) => {
                dispatch({
                  type: RELOAD_LOGIN,
                  payload: true,
                });
                localStorage.clear();
                sessionStorage.clear();
                // dispatch({type: FETCH_ERROR, payload: error.message});
              });
          })
          .catch((error) => {
            dispatch({
              type: RELOAD_LOGIN,
              payload: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            // dispatch({type: FETCH_ERROR, payload: error.message});
          });

        return;
      } catch (err) {
        dispatch(fetchSuccess());
        return;
      }
    };

    const checkAuth = () => {
      Promise.all([firebaseCheck(), awsAuthUser(), validateAuth()]).then(() => {
        setLoading(false);
      });
      Hub.listen('auth', ({payload: {event, data}}) => {
        switch (event) {
          case 'signIn':
            dispatch(onGetLoggedInCognitoUser());
            break;
          case 'signOut':
            dispatch({type: UPDATE_AUTH_USER, payload: null});
            break;
          default:
            return false;
        }
      });
    };
    checkAuth();
  }, [dispatch]);

  //active session entry into user log
  const sesstionTiming = async () => {
    var body = {userCode: user.userCode};
    await jwtAxios
      .post('v1/userMgmt/checkSessionTimingJwt', body)
      .then((res) => {
        body['jwtToken'] = res.data.body.token;
        jwtAxios
          .post('v1/userMgmt/checkSessionTiming', body)
          .then((res) => {})
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error});
          });
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  };
  return [loading, user];
};

export const useAuthUser = () => {
  const {user} = useSelector(({auth}) => auth);

  if (user) {
    return {id: 1, ...user};
  }
  return [null];
};
